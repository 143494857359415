<template>
  <div class="form">
    <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink title="select_banks" service="credit" backlink="/credit/payment" />
      <div class="content">
        <div class="content__container">
          <div class="bank__sort">
            <v-select
              v-model="sort"
              item-text="name"
              item-value="value"
              class="select__dropdown"
              :items="getList('banks_select_1_value_', 4)"
              :hint="'sort' | localize"
              append-icon="icon-arrow-down"
              persistent-hint
            ></v-select>
          </div>
          <div class="bank__container">
            <div class="bank" v-for="(bank, k) in bankSorted" :key="k">
              <p class="bank__caption" :class="{bank__caption_best: isBestBank(bank.id)}">
                <span class="icon-bank"></span>
                {{ 'bank' | localize }} {{ k + 1 }}
              </p>
              <div class="bank__item" :class="{bank__item_best: isBestBank(bank.id)}">
                <div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_payment" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ bank.payment.toLocaleString() }}&nbsp;<span class="icon-₪"></span>
                    </div>
                  </div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_percent" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ bank.percent.toLocaleString() }} %
                    </div>
                  </div>
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_month" | localize }}
                    </div>
                    <div class="bank__row_text">{{ bank.months }}</div>
                  </div>
                </div>
                <div class="bank__bottom">
                  <div class="bank__row">
                    <div class="bank__row_caption">
                      {{ "bank_return" | localize }}
                    </div>
                    <div class="bank__row_text">
                      {{ bank.return.toLocaleString() }}&nbsp;<span class="icon-₪"></span>
                    </div>
                  </div>
                  <router-link
                    :to="{ name: 'Credit offer', params: { bank: bank.id } }"
                    class="button button__form"
                    >{{ "select" | localize }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>

          <div class="error__container">
            <p class="error" v-if="error">{{ error | localize }}</p>
            <p v-else class="info info_3">
              {{ "credit_payment_text" | localize }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <PopupText />
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import PopupText from '@/components/PopupText.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Credit_bank_offers',
  components: { Backlink, PopupText },
  mixins: [commonMixin],
  data: () => ({
    sort: null,
    error: null,
    banks: [],
    userServiceId: null,
    userId: null
  }),
  created () {
    this.userId = this.$store.state.user ? this.$store.state.user.id : 0
    this.$store.commit('togglePopupText', { id: 13, withTitle: false, type: 'popup_info' })
  },
  mounted () {
    this.$store.commit('loading', true)
    this.axios.get('user_service', {
      params: {
        user_id: this.userId,
        service_id: this.$store.state.services.credit
      }
    }).then((response) => {
      this.userServiceId = response.data.id || 0
      this.axios.get('credit/select', { params: { user_service_id: this.userServiceId } }).then((response) => {
        this.banks = response.data
        this.$store.commit('loading', false)
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }).catch((error) => {
      this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
      this.$store.commit('loading', false)
      this.$scrollTo('.error__container', 300, { offset: -400 })
    })
  }
}
</script>
